/* eslint-disable */
import React, { PureComponent } from 'react';
import Helmet from 'react-helmet';
import { Layout } from 'containers';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { FORMAT_GRAPH_DATA } from 'utilities';
import Masonry from 'react-masonry-component';
import { BASE_URL } from 'config';
import {
  Banner,
  TestimonialCard,
} from 'components';
// import {
  // BestInNZ,
  // TourList,
  // TestimonialList,
  // InstagramList,
// } from 'layouts';

class AdventureVsHakaplus extends PureComponent {
  constructor(props) {
    super(props);
    const REVIEWS = FORMAT_GRAPH_DATA(props.data.allReviews);
    this.state = {
      reviews: REVIEWS,
    };
  }

  render() {
    const { reviews } = this.state;
    return (
      <Layout page="adventure-vs-haka-plus">

        <Helmet
          title="What’s the difference between Adventure and Haka Plus tours?"
          meta={[
            {
              name: 'description', content: 'Haka Tours provides inbound tour operator services within New Zealand. Partner with us to get the best New Zealand tour experience for your clients.',
            },
            { name: 'og:title', content: 'What’s the difference between Adventure and Haka Plus tours?' },
            {
              name: 'og:description', content: 'Haka Tours provides inbound tour operator services within New Zealand. Partner with us to get the best New Zealand tour experience for your clients.',
            },
          ]}
          link={[{ rel: 'canonical', href: `${BASE_URL}/adventure-vs-haka-plus/` }]}
        />

        <Banner backgroundImage="https://static-hakatours.imgix.net/queenstown-sub-2-haka-tours-1.jpg?w=1900&h=600&fit=crop&auto=format">
          <h1 className="c-heading c-heading--h1">Choosing Between Haka Adventure and Haka Plus Tours</h1>
        </Banner>
        <section className="l-section-main">
          <div className="l-container l-container--small">
            <div className="u-text--center">
              Our Haka Adventure and Haka Plus Tours are suited to different styles of travellers - depending on the desired accommodation,
              level of activities etc. The key differences are outlined below and you can rest assured, whichever you choose you’ll have an amazing tour!
            </div>
            <br /><br />
            <div className="u-text--center">
              <h4 className="c-heading c-heading--h3">Average Age of Travellers?</h4>
            </div>
            <br />
            <div className="u-text--center main-div">
              <div>
                <div className="adventure">Adventure</div>
                <div className="number">
                  32
                </div>
              </div>
              <hr style={{ height: '9rem', marginTop: '14px' }} />
              <div>
                <div className="hakaplus">Haka Plus</div>
                <div className="number">
                  46
                </div>
              </div>
            </div>
            <br /><br />
            <div className="u-text--center">
              <h4 className="c-heading c-heading--h3">Style of Accommodation?</h4>
            </div>
            <br />
            <div className="u-text--center main-div">
              <div>
                <div className="adventure">Adventure</div>
                <div className="image">
                  <img alt="haka accomodation style" src="https://static-hakatours.imgix.net/haka-accommodation-icon.png?w=150&fit=crop&auto=format" />
                </div>
                <div className="image-text">
                  Backpacker-style dorm rooms. Private room upgrades available
                </div>
              </div>
              <hr style={{ height: '15rem', marginTop: '14px' }} />
              <div>
                <div className="hakaplus">Haka Plus</div>
                <div className="image">
                  <img alt="haka hotel" src="https://static-hakatours.imgix.net/haka-hotel-icon.png?w=150&fit=crop&auto=format" />
                </div>
                <div className="image-text">
                  Hotel-style (3-4 star) twin share with ensuite bathrooms. Private room upgrades available.
                </div>
              </div>
            </div>
            <br /><br /><br />
            <div className="u-text--center">
              <h4 className="c-heading c-heading--h3">Value of included activities?</h4>
            </div>
            <br />
            <div className="u-text--center main-div">
              <div>
                <div className="adventure">Adventure</div>
                <div className="image" style={{ marginTop: '5px' }}>
                  <img alt="haka activities" src="https://static-hakatours.imgix.net/2_thumbs.png?w=200&fit=crop&auto=format" />
                </div>
                <div className="image-text">
                  Examples: learn the haka, carve your own jade necklace, paddle a Māori war canoe, eat a hangi meal, interisland ferry crossing.
                </div>
              </div>
              <hr style={{ height: '14rem', marginTop: '14px' }} />
              <div>
                <div className="hakaplus">Haka Plus</div>
                <div className="image" style={{ marginTop: '5px' }}>
                  <img alt="haka activities" src="https://static-hakatours.imgix.net/3_hands.png?w=200&fit=crop&auto=format" />
                </div>
                <div className="image-text">
                  Examples: sunset boat cruise, wine tasting, scenic e-bike tour, luxury camping experience, suspension bridge tree walk.
                </div>
              </div>
            </div>
            <br /><br /><br />
            <div className="u-text--center">
              <h4 className="c-heading c-heading--h3">What’s the Tour Bus Like?</h4>
            </div>
            <br />
            <div className="u-text--center main-div">
              <div>
                <div className="adventure">Adventure</div>
                <div className="image">
                  <img alt="haka bus" src="https://static-hakatours.imgix.net/haka-bus-icon.png?w=150&fit=crop&auto=format" />
                </div>
                <div className="image-text">
                  22 seater Mitsubishi Rosa (or similar).
                </div>
              </div>
              <hr style={{ height: '14rem', marginTop: '14px' }} />
              <div>
                <div className="hakaplus">Haka Plus</div>
                <div className="image">
                  <img alt="haka bus" src="https://static-hakatours.imgix.net/haka-bus-icon.png?w=150&fit=crop&auto=format" />
                </div>
                <div className="image-text">
                  22 seater Mercedes Benz Sprinter (or similar).
                </div>
              </div>
            </div>
            <br /><br /><br />
            <div className="u-text--center">
              <h4 className="c-heading c-heading--h3">The pace of Life on the Road?</h4>
            </div>
            <br />
            <div className="u-text--center main-div">
              <div>
                <div className="adventure">Adventure</div>
                <div className="image">
                  <img alt="haka pace of life" src="https://static-hakatours.imgix.net/haka-pace-of-life-2-icon.png?w=150&fit=crop&auto=format" />
                </div>
                <div className="image-text">
                  One night stays in most locations, with a few two night stays.
                </div>
              </div>
              <hr style={{ height: '9rem', marginTop: '14px' }} />
              <div>
                <div className="hakaplus">Haka Plus</div>
                <div className="image">
                  <img alt="haka pace of life" src="https://static-hakatours.imgix.net/haka-pace-of-life-icon.png?w=150&fit=crop&auto=format" />
                </div>
                <div className="image-text">
                  Two night stays in most locations, with a few one night stays.
                </div>
              </div>
            </div>
            <br /><br /><br />
            <div className="u-text--center">
              <h4 className="c-heading c-heading--h3">MAXIMUM TOUR GROUP SIZE?</h4>
            </div>
            <br />
            <div className="u-text--center main-div">
              <div style={{ width: '100%' }}>
                <div className="adventure">Adventure & Haka Plus</div>
                <div className="number">
                  16
                </div>
                <div className="image-text">
                  With an average of 12 people on each tour (16 people in our busy summer season).
                </div>
              </div>
            </div>
            <br /><br /><br />
            <div className="u-text--center">
              <h4 className="c-heading c-heading--h3">Number of Solo Travellers?</h4>
            </div>
            <br />
            <div className="u-text--center main-div">
              <div style={{ width: '100%' }}>
                <div className="adventure">Adventure & Haka Plus</div>
                <div className="number" style={{ fontSize: '3rem' }}>
                  70%
                </div>
                <div className="image-text">
                  70% solo travellers, 30% groups of friends and couples.
                </div>
              </div>
            </div>
            <br /><br /><br />
            <div className="u-text--center">
              <h4 className="c-heading c-heading--h3">Excitement & Adventure Factor?</h4>
            </div>
            <br />
            <div className="u-text--center main-div">
              <div style={{ width: '100%' }}>
                <div className="adventure">Adventure & Haka Plus</div>
                <div className="image">
                  <img alt="haka activity" src="https://static-hakatours.imgix.net/haka-activity-icon.png?w=150&fit=crop&auto=format" />
                </div>
                <div className="image-text">
                  Experience New Zealand to the absolute max, or keep it chill and just relax! Choose from over 70 optional activities to make your tour perfect for you.
                </div>
              </div>
            </div>
            <br /><br /><br />
            <div className="u-text--center">
              <h4 className="c-heading c-heading--h3">Activities You Can Add?</h4>
            </div>
            <br />
            <div className="u-text--center main-div">
              <div style={{ width: '100%' }}>
                <div className="adventure">Adventure & Haka Plus</div>
                <div className="number" style={{ fontSize: '4rem' }}>
                  70+
                </div>
                <div className="image-text">
                  Choose from over 70 optional activities you can add to a tour.
                </div>
              </div>
            </div>
            <br /><br /><br />
            <div className="u-text--center">
              <h4 className="c-heading c-heading--h3">What’s are the Tour Guides Like?</h4>
            </div>
            <br />
            <div className="u-text--center main-div">
              <div style={{ width: '100%' }}>
                <div className="adventure">Adventure & Haka Plus</div>
                <div className="image">
                  <img alt="haka tour guide" src="https://static-hakatours.imgix.net/haka-tour-guide-icon.png?w=150&fit=crop&auto=format" />
                </div>
                <div className="image-text">
                  Expert local guide who knows everything about NZ.
                </div>
              </div>
            </div>
          </div>
          <div className="l-testimonial-list" style={{ padding: '0 0 70px' }}>
            <div className="l-container">
              <div className="l-testimonial-list__header">
                <h2 className="c-heading c-heading--h2">
                  Here’s what you’ve said about us:
                </h2>
              </div>
              <Masonry
                className="l-testimonial-list__grid"
                elementType="div"
                disableImagesLoaded={false}
                updateOnEachImageLoad={false}
                options={{
                  gutter: 20,
                  transitionDuration: 0,
                }}
              >
                {reviews.map(item => (
                  <TestimonialCard
                    key={item.id}
                    data={item}
                    textLimit={260}
                  />
                ))}
              </Masonry>

              <div className="l-testimonial-list__header" style={{ marginTop: '3rem' }}>
                <h2 className="c-heading c-heading--h2">
                  Now go and book your adventure of a lifetime!
                </h2>
              </div>

              <div class="blog-container">
                <div>
                  <Link
                    to='new-zealand-tours'
                    className="c-button c-button--primary c-button--wide"
                  >
                    See Adventure Tours
                  </Link>
                </div>
                <div>
                  <Link
                    to='haka-plus-tours'
                    className="c-button c-button--primary c-button--wide"
                  >
                    See Haka Plus Tours
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

      </Layout>
    );
  }
}

AdventureVsHakaplus.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query {
    allReviews(
      limit: 3,
      sort: {
        fields: date_published,
        order: DESC
      }
      filter: {
        id: {
          in: ["__Reviews__5cafbc03da8c320001046408","__Reviews__5cc20ab7fb436d0001c9a456","__Reviews__5c522f315410400001c9ca6b"]
        }
      }
    ) {
      edges {
        node {
          id
          api_id
          author
          country
          text
          image_url
          date_published
        }
      }
    }
  }
`;

export default AdventureVsHakaplus;
